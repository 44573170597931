// TODO: fix in https://app.asana.com/0/1201994806276539/1205107468358935/f
// eslint-disable-next-line import/named
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectHideProgressBar } from 'redux/uiEffects/selectors';
import { selectIsRedesignedFlow } from 'redux/redesignedFlow/selectors';
import { selectOnboardingScreensList } from 'redux/onboadring/selectors';

import { formattedUrlHelper } from 'helpers/utils';

import { HeaderDes } from 'components/redesigned';
import { HeaderTypes } from 'components/Header/types';

import { Header as DefaultHeader } from './Header';
import { HeaderWithComponents } from 'components/HeaderWithComponents';
import useTheme from 'services/Theme/useTheme';

const Header = (props: HeaderTypes) => {
    const theme = useTheme();
    const isRedesignedFlow = useSelector(selectIsRedesignedFlow);
    const obScreenList = useSelector(selectOnboardingScreensList);
    const isProgressBarHidden = useSelector(selectHideProgressBar);

    const currentScreenName = formattedUrlHelper(location.pathname);
    const currentScreenNumber = obScreenList[currentScreenName]?.index;
    const totalScreens = Object.keys(obScreenList).length;
    const isOnboarding = Boolean(obScreenList[currentScreenName]);
    const isOnboardingHeader = isOnboarding && !isProgressBarHidden;

    const extendedProps = {
        ...props,
        isOnboarding,
        isOnboardingHeader,
        progressBar: {
            currentScreen: currentScreenNumber,
            totalScreens,
            width: (currentScreenNumber / totalScreens) * 100 + '%',
        },
    };

    if (isRedesignedFlow) return <HeaderDes {...extendedProps} />;
    if (theme.name === 'green') return <HeaderWithComponents {...extendedProps} />;

    return <DefaultHeader {...extendedProps} />;
};

export default withRouter(Header);
