export const PAYMENT_DEFAULT_CURRENCY = {
    sign: '$',
    name: 'USD',
};

export const EURO = {
    sign: '€',
    name: 'EUR',
};

export const GBP = {
    sign: '£',
    name: 'GBP',
};

export const BRL = {
    sign: 'R$',
    name: 'BRL',
};

export const MXN = {
    sign: 'M$',
    name: 'MXN',
};

export const CLP = {
    sign: 'CLP',
    name: 'CLP',
};

export const COP = {
    sign: 'COL$',
    name: 'COP',
};

export const PEN = {
    sign: 'S/',
    name: 'PEN',
};

export const TWD = {
    sign: 'NT$',
    name: 'TWD',
};

export const PHP = {
    sign: '₱',
    name: 'PHP',
};

export const ZAR = {
    sign: 'R',
    name: 'ZAR',
};

export const AUD = {
    sign: 'A$',
    name: 'AUD',
};

export const CAD = {
    sign: 'C$',
    name: 'CAD',
};
