import { ThemeProvider as WikrThemeProvider } from 'wikr-core-components';
import { ReactNode } from 'react';

import useThemeConfiguration from 'hooks/useThemeConfiguration';

import ThemeContext from './ThemeContext';
import themeConfig from '../../themeConfig.json';

interface ThemeProviderProps {
    children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const theme = useThemeConfiguration(themeConfig);

    return (
        <ThemeContext.Provider value={theme}>
            <WikrThemeProvider theme={theme.themeStyle}>{children}</WikrThemeProvider>
        </ThemeContext.Provider>
    );
};
