import { useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';

import { selectHidePayPal } from 'redux/uiEffects/selectors';
import { selectCurrentProduct } from 'redux/payment/selectors';

interface IUseSolidPaymentPreInit {
    initPayPal: () => void;
    initPaymentGenerator: () => void;
}

function useSolidPaymentPreInit({ initPayPal, initPaymentGenerator }: IUseSolidPaymentPreInit) {
    const currentProduct = useSelector(selectCurrentProduct);
    const isHidePayPal = useSelector(selectHidePayPal);

    const currentProductPrev = useRef<object | string | null>(currentProduct);

    useEffect(() => {
        if (!isHidePayPal && currentProduct?.id) {
            initPayPal();
        }

        initPaymentGenerator();

        currentProductPrev.current = currentProduct;
    }, [currentProduct]);
}

export default useSolidPaymentPreInit;
