function convertEnvToCssVariables(envObject: object) {
    return Object.entries(envObject)
        .map(([key, value]) => `${key}: ${value};`)
        .join('\n');
}

function applyCssVariablesToDOM(envObject: object, id: string) {
    const prevStyles = document.getElementById(id);

    const cssVariables = convertEnvToCssVariables(envObject);
    const styleElement = document.createElement('style');
    styleElement.id = id;
    styleElement.innerHTML = `:root { ${cssVariables} }`;

    if (prevStyles) {
        return prevStyles.replaceWith(styleElement);
    }

    document.head.appendChild(styleElement);
}

export default applyCssVariablesToDOM;
