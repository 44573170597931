import { PersistGate } from 'redux-persist/integration/react';
import { createRoot } from 'react-dom/client';

import 'services/i18n';
import { ThemeProvider } from 'services/Theme';
import Sentry from 'services/Sentry/SentryInstance';

import { ProviderCasted } from 'types/provider';

import configureStore from 'configureStore';

import ErrorBoundary from 'modules/ErrorHandler';
import App from './App';

import './styles.css';

const { store, persistor } = configureStore();

Sentry.init();

const appContainer = document.getElementById('app');
const root = createRoot(appContainer!);

root.render(
    <ProviderCasted store={store}>
        <PersistGate persistor={persistor}>
            <ThemeProvider>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </ThemeProvider>
        </PersistGate>
    </ProviderCasted>
);
