import classNames from 'classnames/bind';

import type { LogoTypes } from 'components/Logo/types';

import styles from './Logo.module.css';

import { ReactComponent as LogoSmallDefault } from './img/logoFotturSmall.svg';
import { ReactComponent as LogoBaseDefault } from './img/logoFottur.svg';
import { ReactComponent as LogoBaseGreen } from './img/logoFottur_green.svg';
import { ReactComponent as LogoSmallGreen } from './img/logoFotturSmall_green.svg';
import useTheme from 'services/Theme/useTheme';

const cx = classNames.bind(styles);

export default function Logo({ centered, white, isOnboarding, onboardingLogo, logoFooter, className }: LogoTypes) {
    const theme = useTheme();

    const logoClass = cx('logo', {
        centered,
        white,
        isOnboarding,
    });
    const logoDefaultImgClass = cx('logoImgDefault', 'logoImgFastEasy', className, {
        logoImgOnboarding: isOnboarding && onboardingLogo,
        logoFooter: logoFooter,
    });

    const LogoSmall = theme.name === 'green' ? LogoSmallGreen : LogoSmallDefault;
    const LogoBase = theme.name === 'green' ? LogoBaseGreen : LogoBaseDefault;

    return (
        <div className={logoClass}>
            {(isOnboarding && onboardingLogo) || logoFooter ? (
                <LogoSmall className={className} />
            ) : (
                <LogoBase className={logoDefaultImgClass} />
            )}
        </div>
    );
}
