import { selectTestaniaName } from 'redux/testania/selectors';
import { takeLatest, put, select, call } from 'redux-saga/effects';

import api from 'apiSingleton';

import { selectUserId } from 'redux/user/selectors';
import { RootState } from 'redux/types';
import {
    selectUpsellId,
    selectCurrentProduct,
    selectPaymentMethod,
    selectCurrency,
    selectOneClickPaymentPrice,
    selectCheckoutOrderId,
} from 'redux/payment/selectors';
import * as paymentActionTypes from 'redux/payment/actionTypes';
import {
    setInitPaymentData,
    setSubscriptionId,
    initOneClickPayment as initOneClickPaymentAction,
    setIsUpsellPaid,
    setIsUpsellSubPaid,
    handleSuccessOneClickPayment,
} from 'redux/payment/actions';

import { PurchaseData } from 'services/Analytics/types';
import { purchase } from 'services/Analytics';

import { isLocalhost, replaceDataWithLocalhost } from 'helpers/environment';

import { ToNextPagePayload } from 'types/payments/payments';
import { PaymentData } from 'types/payments/paymentApiInterfaces';

import { CurrentProduct } from 'interfaces/Payments/payments';
import { collectPaymentAnalyticsSaga } from './collectPaymentAnalyticsSaga';

export const getOneClickPrice = (state: RootState) => selectOneClickPaymentPrice(state);
export const getCurrency = (state: RootState) => selectCurrency(state);
export const getUpsellId = (state: RootState) => selectUpsellId(state);
export const getPaymentMethod = (state: RootState) => selectPaymentMethod(state);
export const getUserId = (state: RootState) => selectUserId(state);
export const getCurrentProduct = (state: RootState) => selectCurrentProduct(state);
export const testaniaName = (state: RootState) => selectTestaniaName(state);
export const getOrderId = (state: RootState) => selectCheckoutOrderId(state);

export function* init({ payload }: ReturnType<typeof initOneClickPaymentAction>) {
    const oneClickPrice: number = yield select(getOneClickPrice);
    const currentProduct: CurrentProduct = yield select(getCurrentProduct);
    const upsellId: number = yield select(getUpsellId);
    const orderId: number = yield select(getOrderId);

    const paymentType = currentProduct?.payment_type;
    const isSubscription = paymentType === 'subscription';

    try {
        yield put({ type: paymentActionTypes.SET_LOADING_STATUS, payload: true });

        const analytic_data: PurchaseData = yield call(collectPaymentAnalyticsSaga, {
            currentProduct,
            oneClickPrice,
            ...payload,
        });

        const meta = {
            product_code: currentProduct.product_code,
            analytic_data,
            ...(!isSubscription && { price: oneClickPrice }),
            ...(isSubscription && { product_id: currentProduct.id }),
        };

        // fix of local problem with SSRF
        isLocalhost() && replaceDataWithLocalhost(meta);

        const upsellResponse: PaymentData = yield api.payment.upSale(meta);
        const subscriptionId = upsellResponse?.order?.subscription_id;

        purchase({
            ...analytic_data,
            subscription_id: subscriptionId,
            order_id: upsellResponse?.order?.order_id || orderId,
        });

        const payment_id = isSubscription ? subscriptionId : upsellResponse?.order?.order_id;

        if (payment_id) yield put(setSubscriptionId(payment_id));

        yield put(setInitPaymentData(upsellResponse));
        yield call(setUpsellId, upsellId);
        yield call(moveToNextPage, true, payload);
        yield put(isSubscription ? setIsUpsellSubPaid(true) : setIsUpsellPaid(true));
    } catch (e) {
        console.error('init oneClickPayment error', e);

        yield call(moveToNextPage, true, payload);
        yield call(errorHandler);
    } finally {
        yield put({ type: paymentActionTypes.SET_LOADING_STATUS, payload: false });
    }
}

export function* handleOneClickResponse({
    payload: { toNextPage, ...payload },
}: ReturnType<typeof handleSuccessOneClickPayment>) {
    const currentProduct: CurrentProduct = yield select(getCurrentProduct);
    const oneClickPrice: number = yield select(getOneClickPrice);
    const productId: string = yield select(selectUpsellId);
    const orderId: number = yield select(getOrderId);

    const analytic_data: PurchaseData = yield call(collectPaymentAnalyticsSaga, {
        currentProduct,
        oneClickPrice,
        ...payload,
    });

    purchase({ ...analytic_data, order_id: orderId });

    yield call(setUpsellId, productId);
    yield call(toNextPage, { isValid: true });
}

export function* moveToNextPage(validateStatus: boolean, payload: ToNextPagePayload) {
    yield call(payload.toNextPage, validateStatus);
}

export function* errorHandler() {
    yield call(setUpsellId, '100');
}

export function* setUpsellId(id: string | number) {
    yield put({ type: paymentActionTypes.SET_UPSELL_PRODUCT_ID, payload: id });
}

export const initOneClickPayment = [takeLatest(paymentActionTypes.INIT_ONE_CLICK_PAYMENT, init)];

export const handleSuccessOneClickWatcher = [
    takeLatest(paymentActionTypes.HANDLE_SUCCESS_ONE_CLICK_PAYMENT, handleOneClickResponse),
];
