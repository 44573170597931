import { Box } from 'wikr-core-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { Suspense } from 'react';

import withHeaderConfig from 'HOC/withHeaderConfig';

import useAppInit from './hooks/useAppInit';

import InitTestania from 'pages/GeneralPages/InitTestania';

import ReleaseConfig from 'components/ReleaseConfig/ReleaseConfig';
import NotificationModal from 'components/NotificationModal';
import NoConnectionModal from 'components/NoConnectionModal';
import Header from 'components/Header';
import LoaderSuspense from './components/LoaderSuspense';

import './styles.css';

import { Routers } from './router';
import { useScrollToTop } from './hookExperiment';

const HeaderWithConfig = withHeaderConfig(Header);

const App = () => {
    useAppInit();
    useScrollToTop();

    return (
        // <OneTrustProvider>
        <>
            <Router>
                <Box className="mainWrapper" backgroundColor="surface-main">
                    <NoConnectionModal />
                    <HeaderWithConfig />
                    <ReleaseConfig />
                    <Suspense fallback={<LoaderSuspense />}>
                        <InitTestania />
                        <Routers />
                    </Suspense>
                </Box>
            </Router>
            <NotificationModal />
        </>
        // </OneTrustProvider>
    );
};

export default App;
