import { Box, Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import classNames from 'classnames/bind';

import { GET_MY_PLAN_BTN } from 'constants/payments';

import Logo from 'components/Logo';
import type { HeaderTypes } from 'components/Header/types';
import Button from 'components/Button';

import styles from './Header.module.css';

import { ReactComponent as SkipArrow } from 'assets/img/items/skipArrow.svg';
import { ReactComponent as BackIcon } from './img/icon-back.svg';

const cx = classNames.bind(styles);

export const HeaderWithComponents = ({
    title = GET_MY_PLAN_BTN,
    squareBtn = false,
    boldButton,
    rowElements,
    history,
    centered,
    getPlanClick,
    disabled,
    onSkipClick,
    isOnboarding,
    isOnboardingHeader,
    progressBar: { currentScreen, totalScreens, width },
}: HeaderTypes) => {
    const headerClass = cx('header', { rowElements });

    return (
        <header className={headerClass}>
            <div className={styles.headerContainer}>
                <div className={styles.top}>
                    {isOnboardingHeader && (
                        <button type="button" className={styles.backButton} onClick={() => history.goBack()}>
                            <BackIcon className={styles.iconBack} />
                        </button>
                    )}
                    <Logo isOnboarding={isOnboarding} centered={centered} onboardingLogo={isOnboardingHeader} />
                    {isOnboardingHeader && (
                        <div className={styles.pageCounter}>
                            <Text type="caption" bold as="span" text={currentScreen.toString()} />
                            <Text type="caption" bold as="span" color="text-secondary-subdued" text="/" />
                            <Text
                                color="text-secondary-subdued"
                                type="caption"
                                bold
                                as="span"
                                className={styles.pageEnd}
                                text={totalScreens.toString()}
                            />
                        </div>
                    )}
                </div>
                {isOnboardingHeader && (
                    <Box className={styles.progressBox} backgroundColor="border-default">
                        <div className={styles.progressBarWrapper} style={{ width }}>
                            <Box backgroundColor="primary-default" className={styles.progressBar} />
                        </div>
                    </Box>
                )}
                {getPlanClick && (
                    <Button
                        dataLocator="headerNavBtn"
                        onClick={getPlanClick}
                        title={title}
                        primary
                        rounded
                        small
                        squareBtn={squareBtn}
                        disabled={disabled}
                        bold={boldButton}
                    />
                )}
                {onSkipClick && (
                    <button onClick={onSkipClick} className={styles.skipButton}>
                        <Trans>skip</Trans>
                        <SkipArrow />
                    </button>
                )}
            </div>
        </header>
    );
};
